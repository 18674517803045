// src/components/Layout.tsx
import React, { useEffect, useState } from 'react';
import Navbar from './Layout/Navbar';
import '../index.css';
import { Outlet } from 'react-router-dom';
import './Layout.css';

interface LayoutProps {
    drawerOpen: boolean;
    toggleDrawer: () => void;
  }

  

const Layout: React.FC<LayoutProps> = ( ) => {
  const [theme, setTheme] = useState<string>('light');

  useEffect (() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme !== null) {
      setTheme(JSON.parse(storedTheme));
    }
  }, []);

  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    setTheme(newTheme);
    localStorage.setItem('theme', JSON.stringify(newTheme));
    console.log(theme);
  };


  return (
    <div className={`container ${theme}`} >
    <Navbar theme={theme} toggleTheme={toggleTheme}/>
          {/* Dinamik içerikler burada Outlet ile yüklenecek */}
    <div className="main-content">
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
