// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
  
}
.container{
  width: 100vw;
  min-height: 100vh;
  background-color: #ced8ff;  
  margin: 0;
  transition: 0.5s;
  
}
  
.container.dark{
  background-color: #222;
}

/* Mobil cihazlar için ekran genişliğini ayarlıyoruz */
@media (max-width: 768px) {
  .container {
    padding: 20px; /* Mobilde iç boşluk ekledim, ekranlar daha dar olduğu için */
  }
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,uBAAuB;;AAEzB;AACA;EACE,YAAY;EACZ,iBAAiB;EACjB,yBAAyB;EACzB,SAAS;EACT,gBAAgB;;AAElB;;AAEA;EACE,sBAAsB;AACxB;;AAEA,sDAAsD;AACtD;EACE;IACE,aAAa,EAAE,6DAA6D;EAC9E;AACF","sourcesContent":["*{\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: sans-serif;\n  \n}\n.container{\n  width: 100vw;\n  min-height: 100vh;\n  background-color: #ced8ff;  \n  margin: 0;\n  transition: 0.5s;\n  \n}\n  \n.container.dark{\n  background-color: #222;\n}\n\n/* Mobil cihazlar için ekran genişliğini ayarlıyoruz */\n@media (max-width: 768px) {\n  .container {\n    padding: 20px; /* Mobilde iç boşluk ekledim, ekranlar daha dar olduğu için */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
