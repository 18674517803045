import React from 'react'
import './Navbar.css'
import logo_light from '../../assets/ygud-forum-new-black.png'
import logo_dark from '../../assets/ygud-forum-new-white.png'
import search_icon_light from '../../assets/search-w.png'
import search_icon_dark from '../../assets/search-b.png'
import toggle_icon_light from '../../assets/night.png'
import toggle_icon_dark from '../../assets/day.png'
import { PersonFill } from 'react-bootstrap-icons';

interface NavbarProps {
  theme: string;
  toggleTheme: () => void;
}

const Navbar: React.FC<NavbarProps> = ({theme, toggleTheme}) => {
  return (
    <div className='navbar'>
     <img src={theme === 'dark' ? logo_dark : logo_light} alt='' className='logo' />
     <ul>
      <li>Home</li>
      <li>Profile</li>
      <li>About</li>
     </ul>
     <div className='search-box'>
      <input type="text" placeholder="Search" />
      <img src={theme === 'dark' ? search_icon_dark : search_icon_light} alt='' className='search-icon'/>
     </div>
     <img onClick={toggleTheme} src={theme === 'dark' ? toggle_icon_dark : toggle_icon_light} alt='' className="toggle-icon"/>
           {/* Profile Section */}
      <div className="profile-section">
      <div className="profile-icon-wrapper">
          <PersonFill className="profile-icon" />
        </div>
        <span className="username">Admin</span>
      </div>

    </div>
  )
}
export default Navbar