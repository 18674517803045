import React, {  useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';


import Layout from './components/Layout';

import ContentPane from './components/ContentPane';

const App: React.FC = () => {

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false); // Sidebar'ın açık olup olmadığını takip ediyoruz

  useEffect(() => {
    const storedDrawerState = localStorage.getItem('drawerOpen');
    if (storedDrawerState !== null) {
      setDrawerOpen(JSON.parse(storedDrawerState));
    }
  }, []);
      
    const toggleDrawer = () => {
      const newDrawerState = !drawerOpen;
      setDrawerOpen(newDrawerState);
      localStorage.setItem('drawerOpen', JSON.stringify(newDrawerState));
    };




  return (
      
      <Router>
      <Routes>
          {/* Layout bileşeni ile Navbar, Sidebar ve Footer sabit */}
          <Route path="/*" element={<Layout drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />}>
            {/* Dinamik içerikler ContentPane içinde */}
            <Route index element={<ContentPane />} /> {/* Varsayılan rota */}

          </Route>
        </Routes>
      </Router>

      
  );
};
export default App;