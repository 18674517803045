// src/components/Home.tsx
import React from 'react';
import { Topic } from '../types/Types';
import './Home.css';
import { HandThumbsUpFill, ChatFill } from 'react-bootstrap-icons';


interface HomeProps {
  topics: Topic[];
}

const Home: React.FC<HomeProps> = ({ topics }) => {
  return (
    <div className="home">
    <ul className="topic-list">
      {topics.map((topic) => (
        <li key={topic.id} className="topic-item">
          <a href={`/topic/${topic.id}`} className="topic-link">
            <h5>{topic.title}</h5>
            <p>{topic.description}</p>
            <div className="topic-meta">
              <HandThumbsUpFill style={{ marginRight: '5px' }} /> 
              <span>{topic.likeCount} Likes</span>
              <span style={{ margin: '0 10px' }}>|</span>
              <ChatFill style={{ marginRight: '5px' }} /> 
              <span>{topic.commentCount} Comments</span>
            </div>
          </a>
        </li>
      ))}
    </ul>
  </div>
  );
};

export default Home;
