// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home {
    padding: 20px;
  }
  
  .topic-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column; /* Alt alta sıralama */
  }
.topic-item {
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 5px;
  width: 100%; /* Tam genişlikte */
  transition: background-color 0.3s ease;
}

.topic-item:hover {
  background-color: #e9ecef;
}

.topic-link {
  text-decoration: none;
  color: inherit;
  display: block;
}

.topic-meta {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  color: #6c757d;
  margin-top: 10px;
}
  
  .topic-link h5 {
    color: #007bff;
    margin: 0;
  }
  
  .topic-link p {
    color: #6c757d;
    margin: 5px 0 0;
  }
  

  
  /* Dark mode styles */
  .dark .home {
    background-color: #212529;
  }
  
  .dark .topic-item {
    background-color: #343a40;
    border-color: #495057;
  }
  
  .dark .topic-item:hover {
    background-color: #495057;
  }
  
  .dark .topic-link h5 {
    color: #17a2b8;
  }
  
  .dark .topic-link p, .dark .topic-meta {
    color: #adb5bd;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Home.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;;EAEA;IACE,gBAAgB;IAChB,UAAU;IACV,SAAS;IACT,aAAa;IACb,sBAAsB,EAAE,sBAAsB;EAChD;AACF;EACE,yBAAyB;EACzB,yBAAyB;EACzB,mBAAmB;EACnB,aAAa;EACb,kBAAkB;EAClB,WAAW,EAAE,mBAAmB;EAChC,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,eAAe;EACf,cAAc;EACd,gBAAgB;AAClB;;EAEE;IACE,cAAc;IACd,SAAS;EACX;;EAEA;IACE,cAAc;IACd,eAAe;EACjB;;;;EAIA,qBAAqB;EACrB;IACE,yBAAyB;EAC3B;;EAEA;IACE,yBAAyB;IACzB,qBAAqB;EACvB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,cAAc;EAChB","sourcesContent":[".home {\n    padding: 20px;\n  }\n  \n  .topic-list {\n    list-style: none;\n    padding: 0;\n    margin: 0;\n    display: flex;\n    flex-direction: column; /* Alt alta sıralama */\n  }\n.topic-item {\n  background-color: #ffffff;\n  border: 1px solid #dee2e6;\n  margin-bottom: 10px;\n  padding: 15px;\n  border-radius: 5px;\n  width: 100%; /* Tam genişlikte */\n  transition: background-color 0.3s ease;\n}\n\n.topic-item:hover {\n  background-color: #e9ecef;\n}\n\n.topic-link {\n  text-decoration: none;\n  color: inherit;\n  display: block;\n}\n\n.topic-meta {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  font-size: 14px;\n  color: #6c757d;\n  margin-top: 10px;\n}\n  \n  .topic-link h5 {\n    color: #007bff;\n    margin: 0;\n  }\n  \n  .topic-link p {\n    color: #6c757d;\n    margin: 5px 0 0;\n  }\n  \n\n  \n  /* Dark mode styles */\n  .dark .home {\n    background-color: #212529;\n  }\n  \n  .dark .topic-item {\n    background-color: #343a40;\n    border-color: #495057;\n  }\n  \n  .dark .topic-item:hover {\n    background-color: #495057;\n  }\n  \n  .dark .topic-link h5 {\n    color: #17a2b8;\n  }\n  \n  .dark .topic-link p, .dark .topic-meta {\n    color: #adb5bd;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
