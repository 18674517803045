// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Layout.css */

.layout-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.main-content {
  flex-grow: 1;
  width: 100%;
  padding: 20px;
  background-color: #ced9fa;
}
`, "",{"version":3,"sources":["webpack://./src/components/Layout.css"],"names":[],"mappings":"AAAA,8BAA8B;;AAE9B;EACE,aAAa;EACb,sBAAsB;EACtB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,yBAAyB;AAC3B","sourcesContent":["/* src/components/Layout.css */\n\n.layout-container {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  height: 100%;\n}\n\n.main-content {\n  flex-grow: 1;\n  width: 100%;\n  padding: 20px;\n  background-color: #ced9fa;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
